var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('section', [_c('validation-observer', {
    ref: "simpleRules"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-card', [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "name"
    }
  }, [_vm._v("Judul")]), _c('small', {
    staticClass: "text-danger"
  }, [_c('b', [_vm._v(" (*)")])]), _c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": "Judul"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "Judul",
            "placeholder": "Masukkan judul soal",
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.name,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "name", $$v);
            },
            expression: "form.name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "Pilih Paket"
    }
  }, [_vm._v("Pilih Kategori Paket")]), _c('small', {
    staticClass: "text-danger"
  }, [_c('b', [_vm._v(" (*)")])]), _c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": "Kategori Paket"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.optpaket_id,
            "reduce": function reduce(option) {
              return option.value;
            },
            "placeholder": "- Pilih -",
            "state": errors.length > 0 ? false : null
          },
          on: {
            "input": function input($event) {
              return _vm.getDataMapel();
            }
          },
          model: {
            value: _vm.form.paket_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "paket_id", $$v);
            },
            expression: "form.paket_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), this.form.paket_id != null ? _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "Pilih Paket"
    }
  }, [_vm._v("Pilih Ujian")]), _c('small', {
    staticClass: "text-link"
  }, [_c('b', [_vm._v(" (opsional)")])]), _c('validation-provider', {
    attrs: {
      "rules": "",
      "name": "Ujian"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.optMapel,
            "reduce": function reduce(option) {
              return option.value;
            },
            "placeholder": "- Pilih Ujian dahulu -",
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.ujian_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "ujian_id", $$v);
            },
            expression: "form.ujian_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3442218201)
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "Waktu (Detik)"
    }
  }, [_vm._v("Waktu (Detik)")]), _c('small', {
    staticClass: "text-danger"
  }, [_c('b', [_vm._v(" (*)")])]), _c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": "Waktu"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "timer_second",
            "placeholder": "Masukkan Waktu (Detik)",
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.timer_second,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "timer_second", $$v);
            },
            expression: "form.timer_second"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _vm.type == 'pauli' ? _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "Jumlah Opsi Soal"
    }
  }, [_vm._v("Jumlah Soal Kolom")]), _c('small', {
    staticClass: "text-danger"
  }, [_c('b', [_vm._v(" (*)")])]), _c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": "Jumlah Opsi"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-input', {
          attrs: {
            "placeholder": "Masukkan jumlah opsi soal",
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.selectedOption,
            callback: function callback($$v) {
              _vm.selectedOption = $$v;
            },
            expression: "selectedOption"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 531591499)
  })], 1)], 1) : _vm._e(), _vm.type == 'pauli' ? _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', [_c('label', {
    attrs: {
      "for": "Jumlah Opsi Soal"
    }
  }, [_c('strong', {
    staticClass: "text-primary"
  }, [_c('i', [_vm._v("Total Bobot Kolom")])])]), _c('div', [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "placeholder": "Masukkan jumlah opsi soal",
      "value": _vm.totalBobotSoal
    }
  })], 1)])], 1) : _vm._e()], 1)], 1), _vm.type != 'pauli' ? _c('b-tabs', [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Pertanyaan")])];
      },
      proxy: true
    }], null, false, 1644594461)
  }, [_c('b-form', [_c('b-row', [_c('b-col', [_c('quill-editor', {
    attrs: {
      "id": "Konten",
      "options": _vm.editor_options
    },
    model: {
      value: _vm.form.content,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)], 1)], 1)], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Pembahasan")])];
      },
      proxy: true
    }], null, false, 1343525816)
  }, [_c('b-form', [_c('b-row', [_c('b-col', [_c('quill-editor', {
    attrs: {
      "id": "Pembahasan",
      "options": _vm.editor_options
    },
    model: {
      value: _vm.form.pembahasan,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pembahasan", $$v);
      },
      expression: "form.pembahasan"
    }
  })], 1)], 1)], 1)], 1)], 1) : _vm._e(), _vm.type == 'pg' || _vm.type == 'multiple_choice' || _vm.type == 'benar_salah' ? _c('div', [_c('b-tabs', {
    staticClass: "mt-2"
  }, _vm._l(_vm.form.soals, function (data, index) {
    return _c('div', {
      key: index
    }, [_c('b-tab', {
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_vm.type != 'pauli' ? _c('div', [index + 1 == 1 ? _c('label', {
            staticClass: "font-weight-bold"
          }, [_vm._v("Opsi. A")]) : _vm._e(), index + 1 == 2 ? _c('label', {
            staticClass: "font-weight-bold"
          }, [_vm._v("Opsi. B")]) : _vm._e(), index + 1 == 3 ? _c('label', {
            staticClass: "font-weight-bold"
          }, [_vm._v("Opsi. C")]) : _vm._e(), index + 1 == 4 ? _c('label', {
            staticClass: "font-weight-bold"
          }, [_vm._v("Opsi. D")]) : _vm._e(), index + 1 == 5 ? _c('label', {
            staticClass: "font-weight-bold"
          }, [_vm._v("Opsi. E")]) : _vm._e()]) : _vm._e()];
        },
        proxy: true
      }], null, true)
    }, [_c('b-form', [_c('b-row', [_c('b-col', [_c('quill-editor', {
      attrs: {
        "id": "Konten",
        "options": _vm.editor_options
      },
      model: {
        value: data.content,
        callback: function callback($$v) {
          _vm.$set(data, "content", $$v);
        },
        expression: "data.content"
      }
    })], 1)], 1), _c('b-row', [_vm.type != 'pg' || _vm.type != 'pauli' ? _c('b-col', {
      attrs: {
        "md": "2"
      }
    }, [index + 1 == 1 ? _c('label', {
      staticClass: "font-weight-bold mt-2"
    }, [_vm._v("Jawaban Opsi. A")]) : _vm._e(), index + 1 == 2 ? _c('label', {
      staticClass: "font-weight-bold mt-2"
    }, [_vm._v("Jawaban Opsi. B")]) : _vm._e(), index + 1 == 3 ? _c('label', {
      staticClass: "font-weight-bold mt-2"
    }, [_vm._v("Jawaban Opsi. C")]) : _vm._e(), index + 1 == 4 ? _c('label', {
      staticClass: "font-weight-bold mt-2"
    }, [_vm._v("Jawaban Opsi. D")]) : _vm._e(), index + 1 == 5 ? _c('label', {
      staticClass: "font-weight-bold mt-2"
    }, [_vm._v("Jawaban Opsi. E")]) : _vm._e(), _vm.type == 'multiple_choice' ? _c('b-form-checkbox', {
      staticClass: "custom-control-primary",
      attrs: {
        "value": "1"
      },
      model: {
        value: data.is_correct,
        callback: function callback($$v) {
          _vm.$set(data, "is_correct", $$v);
        },
        expression: "data.is_correct"
      }
    }, [_vm._v(" Kunci Jawaban ")]) : _vm._e(), _vm.type == 'benar_salah' ? _c('b-form-radio-group', {
      attrs: {
        "options": _vm.bsSoal,
        "name": "radios-stacked",
        "stacked": ""
      },
      model: {
        value: data.is_true_or_false,
        callback: function callback($$v) {
          _vm.$set(data, "is_true_or_false", $$v);
        },
        expression: "data.is_true_or_false"
      }
    }) : _vm._e()], 1) : _vm._e(), _vm.type != 'pg' ? _c('b-col', {
      attrs: {
        "md": "10"
      }
    }, [_c('label', {
      staticClass: "font-weight-bold mt-2"
    }, [_vm._v("Upload file format: jpg, png, pdf, doc, mp3, mp4 (opsional)")]), _c('b-form-file', {
      staticClass: "mb-2",
      attrs: {
        "id": data.id,
        "placeholder": "Pilih file untuk di upload atau unggah...",
        "drop-placeholder": "Drop file here...",
        "no-drop": "",
        "accept": ".jpg, .png, .pdf, .doc, .mp3, .mp4"
      },
      model: {
        value: data.opsi_file,
        callback: function callback($$v) {
          _vm.$set(data, "opsi_file", $$v);
        },
        expression: "data.opsi_file"
      }
    })], 1) : _vm._e(), _vm.type == 'pg' ? _c('b-col', {
      attrs: {
        "md": "12"
      }
    }, [_c('label', {
      staticClass: "font-weight-bold mt-2"
    }, [_vm._v("Upload file format: jpg, png, pdf, doc, mp3, mp4 (opsional)")]), _c('b-form-file', {
      staticClass: "mb-2",
      attrs: {
        "id": data.id,
        "placeholder": "Pilih file untuk di upload atau unggah...",
        "drop-placeholder": "Drop file here...",
        "no-drop": "",
        "accept": ".jpg, .png, .pdf, .doc, .mp3, .mp4"
      },
      model: {
        value: data.opsi_file,
        callback: function callback($$v) {
          _vm.$set(data, "opsi_file", $$v);
        },
        expression: "data.opsi_file"
      }
    })], 1) : _vm._e()], 1)], 1)], 1)], 1);
  }), 0)], 1) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-alert', {
    staticClass: "p-1",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('p', {
    staticClass: "text-dark"
  }, [_c('i', [_vm._v("Sebelum membuat 1 kolom pauli, pastikan anda mengikuti arahan pembuatannya "), _c('strong', {
    staticClass: "cursor-pointer",
    on: {
      "click": function click($event) {
        return _vm.$bvModal.show('petunjuk-modal');
      }
    }
  }, [_vm._v("disini")])])])]), _c('b-card', {
    attrs: {
      "title": _vm.type == 'pauli' ? "Soal Kolom ".concat(_vm.form.soals.length > 0 ? "(".concat(_vm.form.soals.length, ")") : '') : 'Opsi Soal'
    }
  }, [_vm.type == 'pauli' ? _c('div', [_vm.form.soals.length < 1 ? _c('b-alert', {
    staticClass: "p-1",
    attrs: {
      "show": "",
      "variant": "info"
    }
  }, [_vm._v(" Harap isi jumlah soal dalam 1 kolom ")]) : _vm._e(), _vm.form.soals.length >= 1 ? _c('b-alert', {
    staticClass: "mb-1 p-1",
    attrs: {
      "show": "",
      "variant": "info"
    }
  }, [_c('p', {
    staticClass: "text-dark"
  }, [_vm._v(" Anda dapat mengatur seluruh bobot soal dengan "), _c('strong', [_vm._v("meng-input total bobot 1 kolom")]), _vm._v(" disini. Lalu dari angka tersebut akan dibagi rata sesuai jumlah soal dibawah. "), _c('i', [_vm._v("(opsional)")])]), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": "Ex: 10"
    },
    model: {
      value: _vm.bobotKolom,
      callback: function callback($$v) {
        _vm.bobotKolom = $$v;
      },
      expression: "bobotKolom"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm._l(_vm.form.soals, function (data, index) {
    return _c('b-row', {
      key: index,
      staticClass: "mb-1"
    }, [_c('b-col', {
      attrs: {
        "sm": "12",
        "md": "6",
        "lg": "4"
      }
    }, [_c('b-form-group', [_c('label', {
      attrs: {
        "for": 'h-baris-' + (index + 1)
      }
    }, [_c('strong', [_c('i', [_vm._v(_vm._s("Soal Baris " + (index + 1)))])])]), _c('small', {
      staticClass: "text-danger"
    }, [_c('b', [_vm._v(" (*)")])]), _c('validation-provider', {
      attrs: {
        "rules": "required",
        "name": "Soal"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref6) {
          var errors = _ref6.errors;
          return [_c('b-form-input', {
            attrs: {
              "id": 'h-baris-' + data.id,
              "placeholder": "Ex: 1 + 2",
              "state": errors.length > 0 ? false : null
            },
            model: {
              value: data.content,
              callback: function callback($$v) {
                _vm.$set(data, "content", $$v);
              },
              expression: "data.content"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1), _c('b-col', {
      attrs: {
        "sm": "12",
        "md": "6",
        "lg": "4"
      }
    }, [_c('b-form-group', [_c('label', {
      attrs: {
        "for": 'h-baris-' + (index + 1)
      }
    }, [_vm._v(_vm._s("Jawaban Baris " + (index + 1)))]), _c('small', {
      staticClass: "text-danger"
    }, [_c('b', [_vm._v(" (*)")])]), _c('validation-provider', {
      attrs: {
        "rules": "required",
        "name": "Jawaban"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref7) {
          var errors = _ref7.errors;
          return [_c('b-form-input', {
            attrs: {
              "id": 'h-baris-' + data.id,
              "placeholder": "Isi 1 atau 0",
              "state": errors.length > 0 ? false : null
            },
            model: {
              value: data.secret_key,
              callback: function callback($$v) {
                _vm.$set(data, "secret_key", $$v);
              },
              expression: "data.secret_key"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1), _c('b-col', {
      attrs: {
        "sm": "12",
        "md": "6",
        "lg": "4"
      }
    }, [_c('b-form-group', [_c('label', {
      attrs: {
        "for": 'bobot-baris-' + (index + 1)
      }
    }, [_vm._v(_vm._s("Bobot Soal " + (index + 1)))]), _c('small', {
      staticClass: "text-danger"
    }, [_c('b', [_vm._v(" (*)")])]), _c('validation-provider', {
      attrs: {
        "rules": "required",
        "name": "Bobot"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref8) {
          var errors = _ref8.errors;
          return [_c('b-form-input', {
            attrs: {
              "id": 'bobot-baris-' + data.id,
              "placeholder": "Isi angka",
              "state": errors.length > 0 ? false : null
            },
            model: {
              value: data.bobot,
              callback: function callback($$v) {
                _vm.$set(data, "bobot", $$v);
              },
              expression: "data.bobot"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1)], 1);
  }), _vm.form.soals != '' ? _c('b-button', {
    staticClass: "w-full ",
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" Simpan ")]) : _vm._e(), _vm.form.soals != '' ? _c('b-button', {
    staticClass: "w-full mx-1 ",
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit(true);
      }
    }
  }, [_vm._v(" Simpan dan Buat Lagi ")]) : _vm._e()], 2) : _vm._e()])], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "4",
      "lg": "4"
    }
  })], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "id": "petunjuk-modal"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.$bvModal.hide('petunjuk-modal');
            }
          }
        }, [_vm._v("OK")])];
      },
      proxy: true
    }])
  }, [_c('b-alert', {
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" Petunjuk ")]), _c('div', {
    staticClass: "alert-body"
  }, [_c('ol', {
    attrs: {
      "type": "1"
    }
  }, [_c('li', [_vm._v("Untuk mengisi soal pauli, admin tentukan berapa opsi soal yang akan diisi di form "), _c('strong', [_vm._v("\"Jumlah Opsi Soal\"")]), _vm._v(". ")]), _c('li', [_vm._v(" Lalu muncul jumlah opsi soal dan jawaban yang harus diisi oleh admin.. ")]), _c('li', [_vm._v(" Isi form baris 1 dengan soal. "), _c('br'), _vm._v(" Contoh: 1 + 3 atau 3 + 4 ")]), _c('li', [_vm._v(" Lalu sesuaikan isi form jawaban baris 1 dengan jawabannya modulusnya. "), _c('br'), _vm._v(" Contoh: "), _c('br'), _vm._v(" angka "), _c('strong', [_c('u', [_vm._v("1")])]), _vm._v(" jika hasilnya "), _c('strong', [_c('u', [_vm._v("Genap")])]), _vm._v(" "), _c('br'), _vm._v(" atau "), _c('br'), _vm._v(" angka "), _c('strong', [_c('u', [_vm._v("0")])]), _vm._v(" jika hasilnya "), _c('strong', [_c('u', [_vm._v("Ganjil")])])]), _c('li', [_vm._v(" Ulangi langkah 3 dan 4 sesuai dengan baris dan jawaban baris selanjutnya. ")])])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }